// || FONTS.

@font-face {
    font-family: "et-book";
    src: url("/fonts/et-book/et-book-roman-line-figures/et-book-roman-line-figures.eot");
    src: url("/fonts/et-book/et-book-roman-line-figures/et-book-roman-line-figures.eot?#iefix") format("embedded-opentype"), url("/fonts/et-book/et-book-roman-line-figures/et-book-roman-line-figures.woff") format("woff"), url("/fonts/et-book/et-book-roman-line-figures/et-book-roman-line-figures.ttf") format("truetype"), url("/fonts/et-book/et-book-roman-line-figures/et-book-roman-line-figures.svg#etbookromanosf") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "et-book";
    src: url("/fonts/et-book/et-book-display-italic-old-style-figures/et-book-display-italic-old-style-figures.eot");
    src: url("/fonts/et-book/et-book-display-italic-old-style-figures/et-book-display-italic-old-style-figures.eot?#iefix") format("embedded-opentype"), url("/fonts/et-book/et-book-display-italic-old-style-figures/et-book-display-italic-old-style-figures.woff") format("woff"), url("/fonts/et-book/et-book-display-italic-old-style-figures/et-book-display-italic-old-style-figures.ttf") format("truetype"), url("/fonts/et-book/et-book-display-italic-old-style-figures/et-book-display-italic-old-style-figures.svg#etbookromanosf") format("svg");
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "et-book";
    src: url("/fonts/et-book/et-book-bold-line-figures/et-book-bold-line-figures.eot");
    src: url("/fonts/et-book/et-book-bold-line-figures/et-book-bold-line-figures.eot?#iefix") format("embedded-opentype"), url("/fonts/et-book/et-book-bold-line-figures/et-book-bold-line-figures.woff") format("woff"), url("/fonts/et-book/et-book-bold-line-figures/et-book-bold-line-figures.ttf") format("truetype"), url("/fonts/et-book/et-book-bold-line-figures/et-book-bold-line-figures.svg#etbookromanosf") format("svg");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "et-book-roman-old-style";
    src: url("/fonts/et-book/et-book-roman-old-style-figures/et-book-roman-old-style-figures.eot");
    src: url("/fonts/et-book/et-book-roman-old-style-figures/et-book-roman-old-style-figures.eot?#iefix") format("embedded-opentype"), url("/fonts/et-book/et-book-roman-old-style-figures/et-book-roman-old-style-figures.woff") format("woff"), url("/fonts/et-book/et-book-roman-old-style-figures/et-book-roman-old-style-figures.ttf") format("truetype"), url("/fonts/et-book/et-book-roman-old-style-figures/et-book-roman-old-style-figures.svg#etbookromanosf") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

// || FONT-SIZES.
$font-size-1: 4.8rem;
$font-size-2: 3.7rem;
$font-size-3: 2.8rem;
$font-size-4: 2.2rem;
$font-size-5: 1.7rem;
$font-size-6: 1.3rem;
$font-size-7: 1rem;
$font-size-8: 0.8rem;

// || COLORS.

$black-bg: #151515;
$black-fg: #111;
$white-bg: #fffff8;
$white-fg: #ddd;

// || WIDTHS, LINE-HEIGHTS, HEIGHTS, AND SPACINGS.

// Widths for text.
$text-narrow: 20em;
$text-width: 28em;
$text-wide: 34em;

$line-height: 1.5;
$line-height-title: 1;

$height-1: 25vh;
$height-2: 40vh;
$height-3: 70vh;

// Shamelessly copied from Tachyons.
// http://tachyons.io/docs/layout/spacing/
$spacing-none: 0;
$spacing-extra-small: .25rem;
$spacing-small: .5rem;
$spacing-medium: 1rem;
$spacing-large: 2rem;
$spacing-extra-large: 4rem;
$spacing-extra-extra-large: 8rem;
$spacing-extra-extra-extra-large: 16rem;

// || MIXINS.

// Mixins for media queries so it's a lot easier to keep widths consistent across them all.
@mixin medium-size {
  @media (min-width: 641px) {
    @content;
  }
}

@mixin large-size {
  @media (min-width: 1008px) {
    @content;
  }
}
