@charset "utf-8";

//
//
// Sections:
//
// || SITE-WIDE STYLING.
// || HEADER.
// || FOOTER.
// || POSTS.
// || GENERAL PAGE STYLING.
// || SPECIFIC PAGE STYLING.

// || SITE-WIDE STYLING.
html,
body,
div,
article,
section,
main,
footer,
header,
form,
fieldset,
legend,
pre,
code,
a,
h1,h2,h3,h4,h5,h6,
p,
ul,
ol,
li,
dl,
dt,
dd,
textarea,
table,
td,
th,
tr,
input[type="email"],
input[type="number"],
input[type="password"],
input[type="tel"],
input[type="text"],
input[type="url"] {
  box-sizing: border-box;
}

// 1.
// 2.
// 3.
// 4.
body {
  background-color: $white-bg;
  color: $black-fg;
  counter-reset: sidenote-counter; /* 1. */
  font-family: et-book, Palatino, "Palatino Linotype", "Palatino LT STD", "Book Antiqua", Georgia, serif;
  margin: 0 auto; /* 2. */
  max-width: 1400px; /* 3. */
  width: 87.5%; /* 4. */
}

@media (prefers-color-scheme: dark) {
  body {
    background-color: $black-bg;
    color: $white-fg;
  }
}

a {
  text-decoration: dotted underline 1px;
}

// || HEADER.

// TODO: all this is for a desktop view.
.navbar {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  list-style-type: none;
}

@include medium-size {
  .navbar {
    flex-direction: row;
  }
}

.navbar li {
  flex: 1;
}

.navbar a {
  text-decoration: none;
}

// || FOOTER.

.copyright {
  font-size: $font-size-8;
  text-align: center;
}

// || POSTS.

// || GENERAL PAGE STYLING.

// || SPECIFIC PAGE STYLING.

