// I like normalize.css due to it being less intrusive than other resets.
//
// Downloaded directly from the GitHub repository via a rake task. See the
// Rakefile for the repository URL.
@import "normalize";

// Theme used for syntax highlighting, same one I use on vim.
//
// Downloaded from: http://jwarby.github.io/jekyll-pygments-themes/languages/javascript.html
@import "monokai";

// The set of variables I use in the whole website.
// - Font configuration and font selection (e.g. @font-mono, @font-sans)
// - Font sizes
// - Color palette
// - Spacings used when defining margins, paddings, grid layout, etc
@import "design_system";

// Like Normalize, I download the tufte-css file directly from GitHub via a
// rake task. However, I made several changes to the stylesheet and converted
// some conventions to my own—like keeping font config in the design_system and
// organizing my media queries mobile-first. See the Rakefile for the
// repository URL.
//
// TODO: Remove the dependency on tufte-css.
@import "tufte-alt";

// The actual styling for this website.
@import "strange_aeons";
